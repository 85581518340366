import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SmallCard = ({name, icon, className}) => {
    return (
        <div className={className + " small-card-container"}>
            <div className={"small-card gradient-border"}>
                <div className={"wrapper"}>
                    <FontAwesomeIcon icon={icon} size={"1x"}/>
                    <div className={"name"}>{name}</div>
                </div>
            </div>
        </div>
    );
};
export default SmallCard;