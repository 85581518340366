import React from "react";
import SmallCard from "./SmallCard";

import "../css/whatwedo.css";

import {faSnowplow, faTruck, faHome, faHardHat, faShippingFast, faHouseDamage} from "@fortawesome/free-solid-svg-icons";

const WhatWeDo = () => {
    return (
        <div className={"what-we-do"}>
            <div className={"window-title"}>Czym się zajmujemy</div>
            <div className={"offers"}>
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <SmallCard className={"col-lg-2 col-md-4 col-6"} name={"Usługi koparko-ładowarką"} icon={faSnowplow}/>
                        <SmallCard className={"col-lg-2 col-md-4 col-6"} name={"Transport ciężarowy"} icon={faTruck}/>
                        <SmallCard className={"col-lg-2 col-md-4 col-6"} name={"Przygotowanie terenu pod budowę"} icon={faHome}/>
                        <SmallCard className={"col-lg-2 col-md-4 col-6"} name={"Prace ziemne"} icon={faHardHat}/>
                        <SmallCard className={"col-lg-2 col-md-4 col-6"} name={"Transport ziemi, piasku oraz żwiru"} icon={faShippingFast}/>
                        <SmallCard className={"col-lg-2 col-md-4 col-6"} name={"Rozbiórki budynków"} icon={faHouseDamage}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default WhatWeDo;