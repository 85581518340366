import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";

import "../css/header.css";

const Header = () => {
    return (
        <header>
            <div className={"custom-container"}>
                <div className={"contact"}>
                    <div className={"name"}>Dantrans</div>
                    <div className={"info"}>
                        <div className={"email"}>wieslawazaborowska@wp.pl</div>
                        <div className={"wrapper gradient-border"}>
                            <FontAwesomeIcon icon={faPhone} flip={"horizontal"}/>
                            <a href={"tel:500522815"}>
                                <div className={"number"}>500 522 815</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"gradient"}></div>
        </header>
    );
};
export default Header;