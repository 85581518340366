import React from "react";

import "../css/aboutus.css";


const AboutUs = () => {
    return (
        <div id={"about-us"} className={"window secondary-color"}>
            <div className={"row between"}>
                <div className={"col-md-7 col-8 text"}>
                    <div className={"wrapper"}>
                        <div className={"title"}>O nas</div>
                        <div className={"description"}>Jesteśmy firmą świadczącą usługi w zakresie robót ziemnych oraz transportu ciężarowego. Od 2008 roku wykonaliśmy tysiące zleceń dla naszych klientów.</div>
                    </div>
                </div>
                <div className={"col-md-4 col-4 logo"}>
                    <img className={"brand-logo"} src={"logo-2.png"} alt={"logo"}/>
                </div>
            </div>
        </div>
    );
};
export default AboutUs;