import React from "react";

import "../css/twosidecard.css";

const TwoSideCard = ({id, active, name, img, table, callback}) => {
    const getElementsFromTable = () => {
        return table.map((element, i) => {
            return <div key={i} className={"element"}>{element}</div>
        })
    };

    const clickHandle = () => {
        callback(id);
    };

    return (
        <div className={"two-side-card"}>
            <div className={"wrapper " + (active ? "flipped" : null)} onClick={clickHandle}>
                <div className={"first"}>
                    <img src={img} alt={name}/>
                    <div className={"background"}>
                        <div className={"name"}>{name}</div>
                    </div>
                </div>
                <div className={"secondary"}>
                    <div className={"list"}>
                        {getElementsFromTable()}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TwoSideCard;