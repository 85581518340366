import React from "react";

import "../css/footer.css";

const Footer = () => {
    return (
        <footer className={"secondary-color"}>
            <div className={"custom-container"}>
                <div className={"row footer-info"}>
                    <div className={"col-md-4 col-12 column"}>
                        <div className={"wrapper"}>
                            <div className={"title"}>Kontakt</div>
                            <div className={"phone-number"}>Tel. 500 522 815</div>
                        </div>
                        <a href={"tel:500522815"}>
                            <div className={"custom-button gradient-button"}>Zadzwoń</div>
                        </a>
                    </div>
                    <div className={"col-md-4 col-12 column"}>
                        <div className={"wrapper"}>
                            <div className={"title"}>Adres</div>
                            <div className={"address"}>Kczewo 78</div>
                            <div className={"address"}>83-304 Przodkowo</div>
                        </div>
                        <a href={"https://g.page/Dantrans?gm"}>
                            <div className={"custom-button gradient-button"}>Wyznacz trasę</div>
                        </a>
                    </div>
                    <div className={"col-md-4 col-12 column"}>
                        <div className={"work-time"}>
                            <div className={"title"}>Godziny pracy</div>
                            <div className={"days"}>
                                <div className={"day"}>
                                    <div className={"name"}>Poniedziałek</div>
                                    <div className={"hours"}>06:00 - 20:00</div>
                                </div>
                                <div className={"day"}>
                                    <div className={"name"}>Wtorek</div>
                                    <div className={"hours"}>06:00 - 20:00</div>
                                </div>
                                <div className={"day"}>
                                    <div className={"name"}>Środa</div>
                                    <div className={"hours"}>06:00 - 20:00</div>
                                </div>
                                <div className={"day"}>
                                    <div className={"name"}>Czwartek</div>
                                    <div className={"hours"}>06:00 - 20:00</div>
                                </div>
                                <div className={"day"}>
                                    <div className={"name"}>Piątek</div>
                                    <div className={"hours"}>06:00 - 20:00</div>
                                </div>
                                <div className={"day"}>
                                    <div className={"name"}>Sobota</div>
                                    <div className={"hours"}>06:00 - 20:00</div>
                                </div>
                                <div className={"day"}>
                                    <div className={"name"}>Niedziela</div>
                                    <div className={"hours"}>Zamknięte</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"text"}>2017 - 2020 © Wszelkie prawa zastrzeżone.</div>
                </div>
            </div>
            <div className={"gradient"}></div>
        </footer>
    );
};
export default Footer;