import React from 'react';

import "./css/theme.css";
import './App.css';

import Header from "./js/Header";
import AboutUs from "./js/AboutUs";
import WhatWeDo from "./js/WhatWeDo";
import WeWillDo from "./js/WeWillDo";
import Footer from "./js/Footer";
import Comments from "./js/Comments";

function App() {
  return (
    <div className="App fourth-color">
        <Header/>
        <section className={"custom-container"}>
            <div className={"row"}>
                <AboutUs/>
            </div>
            <div className={"row"}>
                <WhatWeDo/>
            </div>
            <div className={"row"}>
                <WeWillDo/>
            </div>
            <div className={"row"}>
                <Comments/>
            </div>
        </section>
        <Footer/>
    </div>
  );
}

export default App;
