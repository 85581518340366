import React, {useState} from "react";

import "../css/wewilldo.css";
import TwoSideCard from "./TwoSideCard";

import useInterval from "./hooks/useInterval";

const WeWillDo = () => {
    const [activeCard, setActiveCard] = useState(1);
    const [delay, setDelay] = useState(3000);

    const clicked = (cardKey) => {
        setActiveCard(cardKey);
        setDelay(5000);
    };

    const checkIfActive = (id) => {
        return activeCard === id;
    };

    const increaseActiveCardId = () => {
        activeCard !== 4 ? setActiveCard(activeCard + 1) : setActiveCard(1);
        if(delay !== 3000) setDelay(3000);
    };

    useInterval(() => {
        increaseActiveCardId();
    }, delay);

    return (
        <div className={"we-will-do"}>
            <div className={"window-title"}>Wykonamy</div>
            <div className={"our-job"}>
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className={"col-lg-3 col-md-6 col-12 card-container"}>
                            <TwoSideCard id={1} active={checkIfActive(1)} name={"Wykopy"} img={"wykopy.jpg"} table={["fundamenty", "domki jednorodzinne, budynki mieszkalne, hale przemysłowe",
                                "szamba", "rozbiórki budynków", "sieć wodociągową, kanalizacyjną itp."]} callback={clicked}/>
                        </div>
                        <div className={"col-lg-3 col-md-6 col-12 card-container"}>
                            <TwoSideCard id={2} active={checkIfActive(2)} name={"Prace ziemne"} img={"wywozy.jpg"} table={["niwelacja terenu", "wymiana gruntów", "skarpowanie", "hałdowanie",
                                "czyszczenie rowów", "wywóz ziemi, gruzu", "wywóz śniegu", "odśnieżanie ulic"]} callback={clicked}/>
                        </div>
                        <div className={"col-lg-3 col-md-6 col-12 card-container"}>
                            <TwoSideCard id={3} active={checkIfActive(3)} name={"Rozbiórki i wyburzenia"} img={"rozbiorki.jpg"} table={["rozbiórki budynków, fundamentów",
                                "nawierchi dróg oraz parkingów", "usługi koparko-ładowarką z młotem wyburzeniowym"]} callback={clicked}/>
                        </div>
                        <div className={"col-lg-3 col-md-6 col-12 card-container"}>
                            <TwoSideCard id={4} active={checkIfActive(4)} name={"Korytowania"} img={"korytowanie.jpg"} table={["dróg", "parkingów", "chodników"]} callback={clicked}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default WeWillDo;