import React from "react";

import "../css/comments.css";

const Comments = () => {
    return (
        <div className={"comments-section"}>
            <div className={"window-title"}>Opinie użytkowników Google Maps</div>
            <div className={"comments"}>
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className={"col-md-4 col-12 card-container"}>
                            <div className={"custom-card"}>
                                <div className={"head"}>
                                    <div className={"img"}><img src={"comments-users/photo.jpg"} alt={"user"}/></div>
                                    <div className={"name"}>Szymon S</div>
                                </div>
                                <div className={"body"}>
                                    <div className={"comment"}>Szybki transport wszelkiego rodzaju gabarytów.</div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-4 col-12 card-container"}>
                            <div className={"custom-card"}>
                                <div className={"head"}>
                                    <div className={"img"}><img src={"comments-users/photo1.png"} alt={"user"}/></div>
                                    <div className={"name"}>Georg K</div>
                                </div>
                                <div className={"body"}>
                                    <div className={"comment"}>Bardzo dobra obsługa, szybko i dokładnie wykonane zlecenie. Polecam</div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-4 col-12 card-container"}>
                            <div className={"custom-card"}>
                                <div className={"head"}>
                                    <div className={"img"}><img src={"comments-users/photo2.png"} alt={"user"}/></div>
                                    <div className={"name"}>Malinaaa</div>
                                </div>
                                <div className={"body"}>
                                    <div className={"comment"}>Polecam, miła obsługa</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Comments;